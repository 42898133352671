
import Header from './components/Header';
import Main from "./components/Main"
import './App.css';
import data from './data';

function App() {

  return (
   <div>
    <Header />
    <Main />
   </div>
  );
}

export default App;
